const Joi = require('joi')

const addressValidator = Joi.object({
  _id: Joi.string(),
  organizationName: Joi.string(),
  location: Joi.object({
    latitude: Joi.number(),
    longitude: Joi.number()
  }),
  nameUkr: Joi.string().
    pattern(/^[^a-z]+$/i).
    messages({
      'string.empty': 'Full address is a mandatory field',
      'string.base': 'Full address is a mandatory field',
      'string.pattern.base': 'Full address must be filled in Ukrainian. Do not use latin characters.'
    }),
  pCode4: Joi.string().
    pattern(/^UA\d{10}$/).
    required().
    messages({
      'string.empty': 'P-code4 is a mandatory field',
      'string.base': 'P-code4 is a mandatory field',
      'string.required': 'P-code4 is a mandatory field',
      'string.pattern.base': 'P-code4 must follow the pattern "UA\\d{10}".'
    }),
  streetType: Joi.string().
    valid(
      'street',
      'alley',
      'avenue',
      'boulevard',
      'square',
      'lane',
      'harbor',
      'highway',
      'bypass',
      'path',
      'breakthrough',
      'descent',
      'impasse',
      'road',
      'thoroughfare',
      'station',
      'passage',
      'maidan',
      'microrayon',
      'street',
      'вулиця',
      'алея',
      'проспект',
      'бульвар',
      'площа',
      'провулок',
      'набережна',
      'шосе',
      'об\'їзд',
      'шлях',
      'просіка',
      'узвіз',
      'тупик',
      'дорога',
      'станція',
      'проїзд',
      'майдан',
      'мікрорайон'
    ).
    messages({
      'string.valid': 'This street type does not exist',
      'string.empty': 'Street type is a mandatory field',
      'string.base': 'Street type is a mandatory field'
    }),
  streetUkr: Joi.string().
    pattern(/^[А-ЯЄІЇҐа-яєіїґ '0-9-]+$/).
    messages({
      'string.empty': 'Street name is a mandatory field',
      'string.base': 'Street name is a mandatory field',
      'string.pattern.base': 'Street name must be filled in Ukrainian. Do not use latin characters.'
    }),
  buildingType: Joi.string().
    required().
    valid('Multi-storey', 'Private estates').
    messages({
      'string.empty': 'Building type is a mandatory field',
      'string.base': 'Building type is a mandatory field',
      'string.required': 'Building type is a mandatory field',
      'string.valid': 'Building type does bust be "Multi-storey" or "Private estates"'
    }),
  corpus: Joi.alternatives().try(Joi.string(), Joi.number()).allow(null, ''),
  createdBy: Joi.string(),
  house: Joi.alternatives().try(Joi.string(), Joi.string().
    min(1).
    max(6).
    messages({
      'string.min': 'House number can\'t be less than 1 symbol',
      'string.max': 'House number can\'t be more than 6 symbols',
      'string.empty': 'House number is a mandatory field',
      'string.base': 'House number is a mandatory field'
    }), Joi.number().min(1)),
  comment: Joi.string().allow(null, '')
}).
  unknown(true)


module.exports = addressValidator


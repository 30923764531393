const en = {
  "BULK_TEMPLATE_TITLE_NEW" : "New bulk import Template",
  'BULK_TEMPLATE_TITLE': 'Bulk Import Template',
  'BULK_GUIDE_TITLE': 'Guide to Using the Bulk Import Template',
  'DOWNLOAD': 'Download',
  'IMPORT_WAITING_MESSAGE': 'Please be patient while we import and compare addresses with existing records. This process ensures that your data is accurately integrated into our system',
  'Corpus': 'Block',
  'Multi-storey': 'Multi-apartment'
}

module.exports = en
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import createMutationsSharer from 'vuex-shared-mutations'
// import md5 from 'md5'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'unhcr_sidar',
})

Vue.use(Vuex)

let waitOrganization = false
// Function to wait for the token
const waitForOrganization = () => {
  return new Promise((resolve) => {
    window.addEventListener('setOrganizations', () => {
      waitOrganization = true
      resolve()
    }, { once: true })
  })
}

export default new Vuex.Store({
  state: {
    user: {},
    breadcrumbs: [],
    organizations: []
  },
  getters: {
    user ({ user }) { return user },
    username ({ user }) { return user?.name },
    userimage ({ user }) {
      if (!user?.email) return ''
      // return `https://www.gravatar.com/avatar/${md5(user?.email?.toLowerCase())}`
      return `/img/default_user_image.jpg`
    },
    breadcrumbs ({ breadcrumbs }) { return breadcrumbs },
    organizations ({ organizations }) { return organizations }
  },
  mutations: {
    setUser (state, data) { state.user = Object.assign({}, data) },
    setOrganizations (state, data) { state.organizations = data },
    setBreadcrumbs (state, data) { state.breadcrumbs = data }
  },
  actions: {
    setUser ({ commit }, data) { commit('setUser', data) },
    async getOrganizations ({ commit }, app) {
      if (!waitOrganization) {
        waitOrganization = true
        let response = await app.$axios.post(`/search`, { table: 'organization', map: true })
        commit('setOrganizations', response.data.values)
        window.dispatchEvent(new CustomEvent('setOrganizations'))
      }
      await waitForOrganization()
      return true
    },
    setBreadcrumbs ({ commit }, data) { commit('setBreadcrumbs', data) }
  },
  modules: {
  },
  plugins: [vuexLocal.plugin, createMutationsSharer({ predicate: ['setUser'] })]
})
